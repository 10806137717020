import React from "react";
import "./style.css";

export const Sp = (): JSX.Element => {
  return (
    <div className="SP">
      <div className="div">
        <div className="overlap">
          <img className="element" alt="Element" src="/img/1-1.png" />
          <div className="view">
            <div className="title">
              <div className="group">
                <div className="overlap-group">
                  <img className="img" alt="Group" src="/img/group-3089.png" />
                  <div className="overlap-wrapper">
                    <div className="overlap-2">
                      <div className="frame">
                        <div className="frame-2">
                          <div className="text-wrapper" style={{
                            whiteSpace: "nowrap"
                          }}>こんなお悩み</div>
                          <div className="overlap-group-wrapper">
                            <div className="overlap-group-2">
                              <div className="rectangle" />
                              <img className="image" alt="Image" src="/img/2x-4.png" />
                            </div>
                          </div>
                        </div>
                        <div className="div-wrapper">
                          <p className="p">
                            <span className="span">が解</span>
                            <span className="text-wrapper-2">決</span>
                            <span className="text-wrapper-3">し</span>
                            <span className="text-wrapper-4">ま</span>
                            <span className="text-wrapper-5">す</span>
                          </p>
                        </div>
                      </div>
                      <div className="text-wrapper-6">！</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-wrapper">
              <div className="frame-3">
                <div className="title-2">
                  <div className="titel">
                    <div className="text-wrapper-7">フレマ</div>
                    <div className="text-wrapper-8">の特徴</div>
                  </div>
                  <div className="rectangle-2" />
                </div>
                <div className="frame-4">
                  <div className="element-2">
                    <div className="frame-5">
                      <img className="group-2" alt="Group" src="/img/group-18-1.png" />
                      <img className="mask-group" alt="Mask group" src="/img/mask-group.png" />
                    </div>
                    <div className="frame-6">
                      <div className="group-3">
                        <div className="rectangle-3" />
                        <div className="rectangle-4" />
                      </div>
                      <div className="text-wrapper-9">
                        性的関係のない交流を目的とした
                        <br />
                        方だけが登録
                      </div>
                      <div className="text-wrapper-10">
                        登録ステップの中で、性的関係を求めないことを確認しています。
                      </div>
                    </div>
                  </div>
                  <div className="element-3">
                    <div className="frame-5">
                      <img className="group-2" alt="Group" src="/img/group-18-1-1.png" />
                      <img className="mask-group-2" alt="Mask group" src="/img/mask-group-1.png" />
                    </div>
                    <div className="frame-7">
                      <div className="group-4">
                        <div className="rectangle-3" />
                        <div className="rectangle-5" />
                      </div>
                      <div className="text-wrapper-11">メッセージでの交流ができるのは身元の確認ができている方のみ</div>
                      <div className="text-wrapper-10">
                        メッセージの送受信には、身分証明書の
                        <br />
                        提出が必須で、18歳以上であることを確認しています。
                      </div>
                    </div>
                  </div>
                  <div className="element-3">
                    <div className="frame-5">
                      <img className="group-2" alt="Group" src="/img/group-18-2-1.png" />
                      <img className="mask-group" alt="Mask group" src="/img/mask-group-2.png" />
                    </div>
                    <div className="frame-7">
                      <div className="group-5">
                        <div className="rectangle-3" />
                        <div className="rectangle-6" />
                      </div>
                      <div className="text-wrapper-9">友情結婚が目的じゃなくても大丈夫！</div>
                      <div className="text-wrapper-10">
                        『結婚は必要ない！でも生涯のパートナーは欲しい！』『1人だけだと将来が不安』など友情結婚を目的としない方にもご利用いただきたいと考えております。
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="view-2">
          <div className="frame-8">
            <div className="title-3">
              <img className="group-6" alt="Group" src="/img/group-3100.png" />
              <div className="text-wrapper-12">フレマなら</div>
              <img className="group-6" alt="Group" src="/img/group-3100-1.png" />
            </div>
            <img className="img-2" alt="Img" src="/img/img-2.png" />
            <div className="text">
              <p className="div-2">
                <span className="text-wrapper-13">出会いたいお相手がどんな方かを設定</span>
                <span className="text-wrapper-14">した上でご利用いただけるようになって</span>
                <span className="text-wrapper-15">いるので</span>
                <span className="text-wrapper-16">、</span>
                <span className="text-wrapper-17">あなたと</span>
                <span className="text-wrapper-18">同じ</span>
                <span className="text-wrapper-19">考</span>
                <span className="text-wrapper-20">えの方と交流</span>
                <span className="text-wrapper-14">
                  することができます。
                  <br />
                  もちろん
                </span>
                <span className="text-wrapper-21">友情結婚</span>
                <span className="text-wrapper-20">のお</span>
                <span className="text-wrapper-21">相</span>
                <span className="text-wrapper-20">手も探せま</span>
                <span className="text-wrapper-22">す</span>
                <span className="text-wrapper-20">！</span>
              </p>
            </div>
          </div>
        </div>
        <div className="view-3">
          <div className="frame-9">
            <div className="frame-10">
              <div className="title-4">
                <img className="img-3" alt="Img" src="/img/img-3.png" />
                <div className="frame-11">
                  <div className="rectangle-7" />
                  <p className="div-3">
                    <span className="text-wrapper-23">実</span>
                    <span className="text-wrapper-24">績</span>
                    <span className="text-wrapper-25">の</span>
                    <span className="text-wrapper-26">あ</span>
                    <span className="text-wrapper-23">
                      る<br />
                    </span>
                    <span className="text-wrapper-24">友情結婚相談</span>
                    <span className="text-wrapper-4">所</span>
                    <span className="text-wrapper-27">カラ</span>
                    <span className="text-wrapper-28">ーズ</span>
                    <span className="text-wrapper-4">が</span>
                    <span className="text-wrapper-29">運</span>
                    <span className="text-wrapper-23">営</span>
                  </p>
                  <img className="logo" alt="Logo" src="/img/logo.png" />
                </div>
              </div>
              <p className="LGBTQ">
                <span className="text-wrapper-30">カラーズは</span>
                <span className="text-wrapper-31">、</span>
                <span className="text-wrapper-32">LGBTQ</span>
                <span className="text-wrapper-33">を含むセクシャル</span>
                <span className="text-wrapper-34">マイノリティ</span>
                <span className="text-wrapper-35">の方々に</span>
                <span className="text-wrapper-36">、</span>
                <span className="text-wrapper-37">恋愛を伴わな</span>
                <span className="text-wrapper-33">い</span>
                <span className="text-wrapper-38">新しい</span>
                <span className="text-wrapper-39">結婚</span>
                <span className="text-wrapper-40">の選択肢を提案する日本初で唯一の結</span>
                <span className="text-wrapper-30">婚相談所です。</span>
              </p>
            </div>
            <img className="frame-12" alt="Frame" src="/img/frame-625365-1.png" />
          </div>
          <p className="div-4">
            <span className="text-wrapper-41">フレマは</span>
            <span className="text-wrapper-42">、</span>
            <span className="text-wrapper-43">そんな</span>
            <span className="text-wrapper-44">カ</span>
            <span className="text-wrapper-45">ラー</span>
            <span className="text-wrapper-46">ズ</span>
            <span className="text-wrapper-45">
              が<br />
            </span>
            <span className="text-wrapper-47">プロ</span>
            <span className="text-wrapper-48">デ</span>
            <span className="text-wrapper-49">ュ</span>
            <span className="text-wrapper-50">ー</span>
            <span className="text-wrapper-51">ス</span>
            <span className="text-wrapper-41">した</span>
            <span className="text-wrapper-51">マ</span>
            <span className="text-wrapper-52">ッ</span>
            <span className="text-wrapper-53">チ</span>
            <span className="text-wrapper-54">ン</span>
            <span className="text-wrapper-4">グ</span>
            <span className="text-wrapper-54">ア</span>
            <span className="text-wrapper-4">プリで</span>
            <span className="text-wrapper-41">す</span>
            <span className="text-wrapper-55">。</span>
          </p>
          <div className="img-4">
            <div className="ellipse-wrapper">
              <div className="ellipse" />
            </div>
            <div className="frame-13">
              <div className="ellipse-2" />
            </div>
            <div className="frame-14">
              <div className="ellipse-3" />
            </div>
          </div>
        </div>
        <div className="cv">
          <div className="overlap-3">
            <div className="back-img" />
            <div className="btn">
              <div className="micro-copy">
                <div className="group-7">
                  <div className="text-wrapper-56">まずは無料で始めてみよう！</div>
                  <div className="text-wrapper-57">＼</div>
                  <div className="text-wrapper-58">
                    ／
                  </div>
                </div>
              </div>
              <div className="btn-2">
                <div className="frame-15">
                  <a href="https://play.google.com/store/apps/details?id=com.colorus81.friema&hl=en&pli=1">
                  <img
                    className="getitongoogleplay"
                    alt="Getitongoogleplay"
                    src="/img/getitongoogleplay-badge-web-color-japanese-1.png"
                  />
                  </a>
                  <a href="https://apps.apple.com/jp/app/friema-%E3%83%95%E3%83%AC%E3%83%9E-%E5%8F%8B%E6%83%85%E7%B5%90%E5%A9%9A%E3%83%9E%E3%83%83%E3%83%81%E3%83%B3%E3%82%B0%E3%82%A2%E3%83%97%E3%83%AA/id1588842731">
                  <img
                    className="download-on-the-app"
                    alt="Download on the app"
                    src="/img/download-on-the-app-store-badge-jp-rgb-blk-100317-1-1.svg"
                  />
                  </a>
                </div>
              </div>
            </div>
            <div className="frame-16">
              <div className="title-5">
                <div className="overlap-group-3">
                  <p className="div-5">
                    <span className="text-wrapper-59">ご好評</span>
                    <span className="text-wrapper-4">につ</span>
                    <span className="text-wrapper-60">
                      き<br />
                    </span>
                    <span className="text-wrapper-59">随時</span>
                    <span className="text-wrapper-61">リニューア</span>
                    <span className="text-wrapper-62">ル</span>
                    <span className="text-wrapper-59">実施中</span>
                  </p>
                  <div className="text-wrapper-63">！</div>
                </div>
              </div>
              <img className="lobo" alt="Lobo" src="/img/lobo.svg" />
            </div>
            <div className="img-5">
              <div className="overlap-4">
                <img className="img-6" alt="Img" src="/img/img.png" />
                <img className="img-7" alt="Img" src="/img/img-1.png" />
              </div>
            </div>
            <div className="title-6">
              <div className="rectangle-8" />
              <p className="div-6">
                <span className="text-wrapper-64">まずは</span>
                <span className="text-wrapper-65">無料で登</span>
                <span className="text-wrapper-66">録</span>
                <span className="text-wrapper-65">
                  ！<br />
                </span>
                <span className="text-wrapper-67">どんな方がいるか見てみましょう！</span>
              </p>
            </div>
          </div>
        </div>
        <img className="image-2" alt="Image" src="/img/image-2.png" />
        <div className="view-4">
          <div className="frame-17">
            <div className="text-wrapper-68">よくある質問</div>
            <div className="frame-18">
              <div className="element-4">
                <div className="frame-19">
                  <div className="group-8">
                    <div className="overlap-group-4">
                      <div className="text-wrapper-69">Q</div>
                    </div>
                  </div>
                  <div className="text-wrapper-70">無料で利用できますか？</div>
                  <div className="line-wrapper">
                    <img className="line" alt="Line" src="/img/line-44.svg" />
                  </div>
                </div>
                <div className="frame-20">
                  <div className="group-8">
                    <div className="overlap-group-4">
                      <div className="text-wrapper-71">A</div>
                    </div>
                  </div>
                  <div className="text-wrapper-72">
                    フレマは無料で登録そして利用も可能です。ただし無料はメッセージ送信と開封は月5通の制限があります。
                  </div>
                </div>
              </div>
              <div className="element-4">
                <div className="frame-19">
                  <div className="group-8">
                    <div className="overlap-group-4">
                      <div className="text-wrapper-69">Q</div>
                    </div>
                  </div>
                  <div className="text-wrapper-73">
                    フレマは、どんな人でも
                    <br />
                    登録できますか？
                  </div>
                  <div className="line-wrapper">
                    <img className="line" alt="Line" src="/img/line-44.svg" />
                  </div>
                </div>
                <div className="frame-20">
                  <div className="group-8">
                    <div className="overlap-group-4">
                      <div className="text-wrapper-71">A</div>
                    </div>
                  </div>
                  <div className="text-wrapper-72">
                    18歳以上、そして性的関係を求めないお相手や友達を探している方であればご登録いただけます。
                  </div>
                </div>
              </div>
              <div className="element-4">
                <div className="frame-19">
                  <div className="group-8">
                    <div className="overlap-group-4">
                      <div className="text-wrapper-69">Q</div>
                    </div>
                  </div>
                  <div className="text-wrapper-73">友情結婚したいか迷っていますが利用できますか？</div>
                  <div className="line-wrapper">
                    <img className="line" alt="Line" src="/img/line-44.svg" />
                  </div>
                </div>
                <div className="frame-20">
                  <div className="group-8">
                    <div className="overlap-group-4">
                      <div className="text-wrapper-71">A</div>
                    </div>
                  </div>
                  <div className="text-wrapper-72">
                    利用できます。フレマは、結婚以外で、生涯のパートナーや友達が欲しいという方のためのアプリでもあります。
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="youtube">
            <div className="frame-21">
              {/* <img className="movie" alt="Movie" src="/img/movie.svg" /> */}
              <iframe width="375" height="212" src="https://www.youtube.com/embed/r1sYGhY1MV8?si=TyNEZRI2merUwiZe" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
              <div className="frame-22">
                <img className="yt-logo-rgb-light" alt="Yt logo rgb light" src="/img/yt-logo-rgb-light-1.png" />
                <div className="frame-23">
                  <div className="text-wrapper-74">
                    セクシャリティや友情結婚に
                    <br />
                    ついて発信中！
                  </div>
                </div>
              </div>
              <a className="frame-24" href="https://www.youtube.com/@colorus81" target="_blank">
                <img className="ei-sc-youtube" alt="Ei sc youtube" src="/img/ei-sc-youtube.svg" />
                <div className="text-wrapper-75">YouTubeを見る！</div>
              </a>
            </div>
          </div>
          <footer className="footer">
            <div className="frame-9">
              <div className="frame-25">
                <div className="frame-9">
                  <img className="g" alt="G" src="/img/g-14.png" />
                  <div className="frame-26">
                    <div className="frame-7">
                      <div className="text-wrapper-76">株式会社COLORUS81</div>
                      <div className="text-wrapper-77">〒107-0062 東京都港区南青山3-1-3-6F</div>
                    </div>
                  </div>
                </div>
                <div className="frame-9">
                  <a className="frame-27" href="https://www.youtube.com/@colorus81" target="_blank">
                    <img className="ei-sc-youtube-2" alt="Ei sc youtube" src="/img/ei-sc-youtube-1.svg" />
                    <p className="you-tube">
                      <span className="text-wrapper-78">
                        YouTube
                        <br />
                      </span>
                      <span className="text-wrapper-79">友情結婚相談所カラーズ</span>
                    </p>
                  </a>
                  <div className="flexcontainer">
                    <p className="text-2">
                      <span className="text-wrapper-80">このチャンネルでは</span>
                      <span className="text-wrapper-81">
                        友情結婚相談所を運営するカラーズが、友情結婚に関する情報を分かりやすく紹介
                      </span>
                      <span className="text-wrapper-80">
                        する動画を発信します。
                        <br />
                      </span>
                    </p>
                    <p className="text-2">
                      <span className="text-wrapper-80">
                        友情結婚とは、恋愛関係にない者同士がお互いの考え方・相性・条件などの一致により、
                      </span>
                      <span className="text-wrapper-81">
                        性愛はなくても友情や愛情など様々な気持ちのつながりでwin-winな婚姻を結ぶ
                      </span>
                      <span className="text-wrapper-80">
                        ことを言います。
                        <br />
                      </span>
                    </p>
                    <p className="text-2">
                      <span className="text-wrapper-80">所謂偽装結婚とは異なる、</span>
                      <span className="text-wrapper-81">法に基づいた男女の合意のもとの正式な結婚</span>
                      <span className="text-wrapper-80">です。カラーズは</span>
                      <span className="text-wrapper-81">日本初で唯一の友情結婚を専門とした結婚相談所で</span>
                      <span className="text-wrapper-82">、</span>
                      <span className="text-wrapper-81">
                        2025年2月に10周年を迎えます。
                        <br />
                      </span>
                    </p>
                    <p className="text-2">
                      <span className="text-wrapper-80">
                        カラーズの事業は、経済産業省からの補助金「平成25年度補正予算創業補助金」に採択されております。
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="frame-28">
                <div className="frame-29">
                  <p className="div-7">
                    <span className="text-wrapper-83">＼日本</span>
                    <span className="text-wrapper-84">初</span>
                    <span className="text-wrapper-85">！</span>
                    <span className="text-wrapper-83">／</span>
                  </p>
                  <a href="https://www.colorusfsb.com/" target="_blank">
                    <img className="frame-30" alt="Frame" src="/img/frame-625387-1.png" />
                  </a>
                </div>
                <div className="frame-31">
                  <a href="https://www.colorusfsb.com/aboutfsb" target="_blank">
                  <img className="frame-32" alt="Frame" src="/img/frame-625388-1.png" />
                  </a>
                  <a href="https://www.colorusfsb.com/diagnosis/" target="_blank">
                  <img className="frame-33" alt="Frame" src="/img/frame-625389-1.png" />
                  </a>
                </div>
              </div>
              <div className="text-btn">
                <a href="/privacy.html" className="text-wrapper-86">プライバシーポリシー</a>
                <a href="/terms.html" className="text-wrapper-87">利用規約</a>
              </div>
            </div>
            <div className="text-wrapper-88">Copyright © FrieMa+2024.</div>
          </footer>
        </div>
        <div className="view-5">
          <div className="div-8">
            <p className="div-9">
              <span className="text-wrapper-89">こんな方に</span>
              <span className="text-wrapper-90">おすすめ</span>
              <span className="text-wrapper-30">です！</span>
            </p>
          </div>
          <div className="frame-34">
            <div className="element-5">
              <div className="frame-35">
                <div className="img-wrapper">
                  <img className="group-9" alt="Group" src="/img/group-67.png" />
                </div>
                <div className="text-wrapper-91">
                  生涯支え合える
                  <br />
                  パートナーが欲しい人
                </div>
              </div>
            </div>
            <div className="element-6">
              <div className="frame-35">
                <div className="frame-36">
                  <div className="element-wrapper">
                    <img className="element-7" alt="Element" src="/img/1.svg" />
                  </div>
                </div>
                <div className="text-wrapper-91">
                  友情結婚を
                  <br />
                  希望している人
                </div>
              </div>
            </div>
            <div className="element-8">
              <div className="frame-37">
                <div className="img-wrapper">
                  <img className="frame-38" alt="Frame" src="/img/frame-111.svg" />
                </div>
                <div className="text-wrapper-91">
                  同じようなセクシャリティ
                  <br />
                  の友達が欲しい人
                </div>
              </div>
              <div className="frame-39">
                <div className="frame-40" />
                <div className="frame-41">
                  <div className="ellipse-4" />
                </div>
                <div className="frame-42">
                  <div className="ellipse-5" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="fv">
          <div className="btn-3">
            <div className="div-8">
              <div className="text-wrapper-92">＼</div>
              <div className="text-wrapper-92">まずは無料で始めてみよう！</div>
              <div className="text-wrapper-93">
                ／
              </div>
            </div>
            <div className="btn-4">
              <a href="https://play.google.com/store/apps/details?id=com.colorus81.friema&hl=en&pli=1">
              <img
                className="getitongoogleplay"
                alt="Getitongoogleplay"
                src="/img/getitongoogleplay-badge-web-color-japanese-1.png"
              />
              </a>
              <a href="https://apps.apple.com/jp/app/friema-%E3%83%95%E3%83%AC%E3%83%9E-%E5%8F%8B%E6%83%85%E7%B5%90%E5%A9%9A%E3%83%9E%E3%83%83%E3%83%81%E3%83%B3%E3%82%B0%E3%82%A2%E3%83%97%E3%83%AA/id1588842731">
              <img
                className="download-on-the-app"
                alt="Download on the app"
                src="/img/download-on-the-app-store-badge-jp-rgb-blk-100317-1.svg"
              />
              </a>
            </div>
          </div>
          <div className="overlap-5">
            <div className="element-merit">
              <div className="overlap-6">
                <div className="element-9">
                  <img className="image-3" alt="Image" src="/img/2x.png" />
                  <img className="group-10" alt="Group" src="/img/group-3153.png" />
                </div>
                <div className="group-wrapper">
                  <img className="group-11" alt="Group" src="/img/group-3160.png" />
                </div>
                <div className="element-10">
                  <img className="image-4" alt="Image" src="/img/2x-3.png" />
                  <img className="image-5" alt="Image" src="/img/2x-2.png" />
                  <div className="overlap-group-5">
                    <img className="image-6" alt="Image" src="/img/image.svg" />
                    <img className="element-11" alt="Element" src="/img/3000.png" />
                  </div>
                </div>
              </div>
            </div>
            <img className="element-12" alt="Element" src="/img/0805-1.png" />
          </div>
          <div className="catchcopy">
            <div className="logo-2">
              <div className="overlap-group-6">
                <div className="text-wrapper-94">フレマ</div>
              </div>
            </div>
            <img className="catchcopy-2" alt="Catchcopy" src="/img/catchcopy.svg" />
          </div>
        </div>
        <img className="element-13" alt="Element" src="/img/1-1-2.png" />
      </div>
    </div>
  );
};
